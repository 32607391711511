<template>
  <header v-if="plan" class="helsinki">
    <div> <!-- top row -->
      <!-- breadcrumb -->
      <menu class="breadcrumb">
        <ul><PortalLink view="hcplanning" class="bubble">Headcount Planning</PortalLink></ul>
        <ul><PortalLink view="hcplanning/admin" class="bubble">Admin</PortalLink></ul>
        <ul v-if="plan.parent_plan"><a :href="`/hcplanning/${plan.parent_plan}`" class="bubble plan-label">{{ plan.parent_plan_title }}</a></ul>
        
        <ul v-if="newSubplan"><span class="focus">Create subplan</span></ul>
        <ul v-else><a :href="`/hcplanning/${plan.id}`" class="bubble plan-label">{{ plan.title }} - Admin</a></ul>
      </menu>

      <!-- user notifications -->
      <div class="notifications">
        <div class="changes-saved-notice" ref="changesSavedNotice"><i class="fa-solid fa-check"></i>Changes saved!</div>
      </div>
    </div>

    <div> <!-- bottom row -->
      <!-- tab navigation -->
      <nav>
        <div :class="{active: activeTab === 'settings'}">
          <button class="btn-new subtle" @click="switchTab('settings')">Plan settings</button>
        </div>
        <div :class="{active: activeTab === 'restrictedViewers'}">
          <button class="btn-new subtle" @click="switchTab('restrictedViewers')">Restricted viewers</button>
        </div>
      </nav>
      
      <!-- view toolbar: plan -->
      <div class="toolbar">
        <button class="btn-new" @click="savePlan" :disabled="!allFieldsCorrect" v-if="activeTab === 'settings'"
          :title="!allFieldsCorrect ? 'Make sure every required field is filled in correctly.' : ''">
          <i class="fa-solid fa-save"></i>Save changes</button>
      </div>
    </div>
  </header>

  <main v-if="plan != null" class="helsinki">      
      <div class="plans-tab" v-if="activeTab === 'settings'">
        <dl v-if="plan" class="listing-table">
          <dt>ID</dt>
          <dd>{{ plan.id }}</dd>
          <!-- -- -->
          <dt v-if="plan.parent_plan">Parent Plan</dt>
          <dd v-if="plan.parent_plan">{{ plan.parent_plan_title }}</dd>
          <!-- -- -->
          <dt>Title</dt>
          <dd><input type="text" v-model="plan.title" placeholder="Plan title" required /></dd>
          <!-- -- -->
          <dt>SupOrg</dt>
          <dd style="display: flex; flex-direction: column; gap: 1ex">
            <div v-for="org in plan.sup_orgs" :key="org[0]">
              <FilterDropdown :value="org[0]" :text="org[1]" :items="supOrgs" 
                @select="org[0] = $event.id; org[1] = $event.value" />
              <button class="btn-new subtle-icon compact" style="margin-left: 1em;" @click="plan.sup_orgs.splice(plan.sup_orgs.indexOf(org), 1)"><i class="fa-solid fa-trash"></i></button>
            </div>
            <div> <!-- add new suporg -->
              <FilterDropdown :value="newSupOrg[0]" :text="newSupOrg[1]" :items="supOrgs" 
                @select="newSupOrg[0] = $event.id; newSupOrg[1] = $event.value" />
              <button class="btn-new subtle-icon compact" style="margin-left: 1em;" @click="plan.sup_orgs.push(newSupOrg); newSupOrg=[null, null]"><i class="fa-solid fa-plus"></i></button>
            </div>
          </dd>
          <!-- -- -->
          <dt>Plan Owner</dt>
          <dd>
            <SelectUserInput :value="plan.owner" dataUrl="/api/hcp/plans/0/hiringmanagers" dataCacheKey="hcp_hiringmanagers" class="ownerSelect"
              :text="(plan.owner_first_name ? `${plan.owner_first_name} ${plan.owner_last_name}` : '') || plan.owner" :class="{ 'invalid-field': !plan.owner }"
              @select="plan.owner = $event.id; [plan.owner_first_name, plan.owner_last_name] = $event.name.split(' ', 2); findInvalidFields()"
              @change="findInvalidFields"></SelectUserInput>
          </dd>
          <!-- -- -->
          <dt>Approvers</dt>
          <dd>
            <MultiUserInput @select="plan.agents = $event.map(obj => obj.id)" dataCacheKey="hcp_recruiters" :dataUrl="`/api/hcp/plans/${planId}/recruiters`"
              :initial-users="plan.agents" />
          </dd>
          <!-- -- -->
          <!-- <dt>Approvers</dt>
          <dd>
            <MultiUserInput @select="plan.approvers = $event.map(obj => obj.id)" :dataUrl="`/api/hcp/plans/${planId}/recruiters`" dataCacheKey="hcp_recruiters"
              :initial-users="plan.approvers" />
          </dd> -->
          <!-- -- -->
          <dt>Viewers</dt>
          <dd>
            <MultiUserInput @select="plan.viewers = $event.map(obj => obj.id)" :dataUrl="`/api/hcp/plans/${planId}/recruiters`" dataCacheKey="hcp_recruiters"
              :initial-users="plan.viewers" />
          </dd>
          <!-- -- -->
          <dt>Editors</dt>
          <dd>
            <MultiUserInput @select="plan.editors = $event.map(obj => obj.id)" :dataUrl="`/api/hcp/plans/${planId}/recruiters`" dataCacheKey="hcp_recruiters"
              :initial-users="plan.editors" />
          </dd>
          
          <!-- -- -->
          <dt>Cost Centers</dt>
          <dd>
            <FilterDropdown :value="plan.extra_data.cost_center" :text="plan.extra_data.cost_center_name" :items="costCenters" 
              @select="plan.extra_data.cost_center = $event.id; plan.extra_data.cost_center_name = $event.value" />
          </dd>
          <!-- -- -->
          <!-- <dt>Countries</dt>
          <dd>
            <select v-model="plan.extra_data.countries" v-if="woltCountries" multiple>
              <option v-for="country in woltCountries" :key="country.id" :value="country.value">{{ country.value }}</option>
            </select>
          </dd> -->
          <!-- -- -->
          <dt>Total Budget</dt>
          <dd><input type="number" v-model="plan.extra_data.total_funds" placeholder="10000" /></dd>
          <!-- -- -->
          <dt>Position Prefix</dt>
          <dd><input type="text" v-model="plan.extra_data.position_prefix" placeholder="Position prefix" /></dd>
          <!-- -- -->
          <dt>Next position id</dt>
          <dd>{{ plan.next_pos_id }}</dd>
          <!-- -- -->
          <dt>Actuals</dt>
          <dd><input type="text" v-model="plan.extra_data.actuals" placeholder="Actuals" /></dd>
          <!-- -- -->
          <dt>Plan category</dt>
          <dd><input type="text" v-model="plan.extra_data.category" placeholder="Plan category" /></dd>
          <!-- -- -->
          <dt>Plan Status</dt>
          <dd>
            <select v-model="plan.status">
              <option :value="0">INACTIVE</option>
              <option :value="10">ACTIVE</option>
            </select>
          </dd>          
          <!-- -- -->
          <dt>Dynamic view query</dt>
          <dd><input type="text" v-model="plan.extra_data.dynamic_view_query" placeholder="Dynamic view query" /></dd>
          <!-- -- -->
          <dt>Position settings</dt>
          <dd>
            <select v-model="plan.extra_data.pos_settings">
              <option value="all">Create and edit</option>
              <option value="edit">Edit only</option>
              <option value="readonly">Read only</option>
            </select>
          </dd>  
        </dl>

        <button class="btn-new" @click="savePlan"><i class="fa-solid fa-save"></i>Save changes</button>
      </div>
      <div class="plans-tab" v-if="activeTab === 'restrictedViewers'">
        <table class="data-table">
          <thead>
            <tr class="data-header">
              <th style="width: 250px;">User</th>
              <th style="width: 100px;">Country</th>
              <th style="width: 100px;">Role</th>
              <th></th>
            </tr>
          </thead>
  
          <tbody>
            <tr class="data-row" v-for="row in restrictedViewers" :key="row.id">
              <td>{{ row.user_name }}</td>
              <td>{{ row.country_name }}</td>
              <!-- <td>{{ row.role }}</td> -->
              <td><button class="btn-new subtle-icon compact" @click="deleteRestrictedViewer(row, restrictedViewers).then(r => restrictedViewers = r)"><i class="fa-solid fa-trash"></i></button></td>
            </tr>
            <tr class="data-row">
              <td>
                <SelectUserInput :value="newRestrictedViewer.user_id" :text="newRestrictedViewer.user_name || 'Select user...'"
                  dataCacheKey="hcp_recruiters" dataUrl="/api/hcp/plans/0/recruiters"
                  @select="newRestrictedViewer.user_id = $event.id; newRestrictedViewer.user_name = $event.name"></SelectUserInput>
              </td>
              <td>
                <select v-model="newRestrictedViewer.country" v-if="allCountries">
                  <option v-for="c in allCountries" :key="c.id" :value="c.id" :selected="c.id === newRestrictedViewer.country">{{ c.value }}</option>
                </select>
              </td>
              <!-- <td>
                <select v-model="newRestrictedViewer.role">
                  <option value="TA_LEAD" selected>TA Lead</option>
                  <option value="MXTA_LEA" selected>Mx TA Lead</option>
                </select>
              </td> -->
              <td>
                <button class="btn-new" @click="addRestrictedViewer(newRestrictedViewer, restrictedViewers, newRestrictedViewer.role); newRestrictedViewer={}"><i class="fa-solid fa-plus"></i> Add</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </main>
  </template>
  
  <script>    
    import SelectUserInput from "@/components/objects/inputs/SelectUserInput.vue";
    import MultiUserInput from "@/components/objects/inputs/MultiUserInput.vue";
    import FilterDropdown from "@/components/objects/inputs/FilterDropdown.vue";
    import PortalLink from '@/components/PortalLink.vue'
    import { fetchFromApi, putToApi, postToApi, deleteFromApi } from "@/services/AppTools";
    import { permissions } from "@/services/Permissions";
  
    const NEW_PLAN_ID = "<new plan>";

    export default {
      name: "HcAllPlansView",
  
      components: {
        SelectUserInput,
        MultiUserInput,
        FilterDropdown,
        PortalLink,
      },
  
      data() {
        return {
          plan: null,
          newSubplan: false,
          oldPlans: [],
          allPlans: [],
          woltCountries: [],
          costCenters: [],
          supOrgs: [],

          newSupOrg: [null, null],

          roles: [],
          restrictedViewers: null,
          newRestrictedViewer: {},
          allCountries: null,

          allFieldsCorrect: true,
          activeTab: "settings",
  
          permissions,
        }
      },
  
      methods: {
        async switchTab(tab) {
          if (tab === this.activeTab) return;
          // if (tab === "settings") {} 
          if (tab === "restrictedViewers") {
            this.restrictedViewers = await fetchFromApi(this, `/api/hcp/roles/plan/${this.plan.id}?type=RESTRICTED_VIEWER`);

            const editValues = await fetchFromApi("GET", `/api/hcp/plans/${this.planId}/editvalues`, null, {"regions": true});
            this.allCountries = editValues.countries;
          }
          this.activeTab = tab;
        },
        async addRestrictedViewer(role, collection) {
          role.role = "RESTRICTED_VIEWER";
          const result = await postToApi(this, `/api/hcp/roles/plan/${this.plan.id}`, role);
          role.id = result.id;
          role.country_name = result.country_name;

          collection.push(role);
        },

        async deleteRestrictedViewer(role, collection) {
          await deleteFromApi(`/api/hcp/roles/plan/${this.plan.id}/${role.id}`);
          return collection.filter(item => item.id != role.id);
        },
        findInvalidFields() {
          // find fields with invalid-field CSS class, or unfulfilled required fields
          if (this.$el && this.$el.querySelectorAll) {
            let invalidFields = this.$el.querySelectorAll(".invalid-field, :required:placeholder-shown");
          
            this.allFieldsCorrect = invalidFields.length === 0;
          }
        },

        async savePlan() {
          let plan = this.plan;

          let planData = {
              title: plan.title,
              extra_data: plan.extra_data,
              parent_plan: plan.parent_plan,
              owner: plan.owner,
              agents: plan.agents ? plan.agents.map(x => isNaN(x) ? x[0] : x) : null, // x is id or id/name tuple
              approvers: plan.approvers ? plan.approvers.map(x => isNaN(x) ? x[0] : x) : null, // x is id or id/name tuple
              viewers: plan.approvers ? plan.viewers.map(x => isNaN(x) ? x[0] : x) : null, // x is id or id/name tuple
              editors: plan.editors ? plan.editors.map(x => isNaN(x) ? x[0] : x) : null, // x is id or id/name tuple
              scope: plan.scope,
              status: plan.status,
              sup_orgs: plan.sup_orgs,
          };

          if(plan.id === NEW_PLAN_ID) {
            const url = this.planId != 'new' ? `/api/hcp/admin/${this.planId}/subplan` : `/api/hcp/admin`;
            const new_plan = await postToApi(this, url, planData);

            await this.$router.push(`/hcplanning/admin/${new_plan.id}`);
            this.$router.go();
          } else {
            await putToApi(this, `/api/hcp/admin/${plan.id}`, planData);
          }

          this.$refs.changesSavedNotice.animate([
            { opacity: 0, transform: "translateX(2em)" },
            { opacity: 1, transform: "translateX(0)" },
          ], {
            duration: 500,
            easing: "ease-out",
            fill: "forwards",
          });

          setTimeout(() => {
            if (!this.$refs.changesSavedNotice) return;

            this.$refs.changesSavedNotice.animate([
              { opacity: 1 },
              { opacity: 0 },
            ], {
              duration: 125,
              easing: "ease-in",
              fill: "forwards",
            });
          }, 2000);

        },
      },
  
      async beforeMount() {
        this.newSubplan = this.$route.params.view == "new-subplan";
        this.$root.appClass["page-helsinki"] = true;
        this.planId = this.$route.params.id;
        this.newPlan = this.planId == "new"
        if(this.newPlan) {
          this.plan = {
            id: NEW_PLAN_ID,
            title: "New Plan",
            extra_data: { countries: [] },
            parent_plan: null,
            parent_plan_title: null,
            owner: null,
            sup_orgs: [],
          };
        } else if(this.newSubplan) {
          const parentPlan = await fetchFromApi(this, `/api/hcp/admin/${this.planId}`)
          this.plan = {
            id: NEW_PLAN_ID,
            title: "New Plan",
            extra_data: {},
            parent_plan: parentPlan.id,
            parent_plan_title: parentPlan.title,
            owner: null,
            sup_orgs: [],
          };
        } else {
          this.plan = await fetchFromApi(this, `/api/hcp/admin/${this.planId}`)
          this.plan.extra_data = this.plan.extra_data ? JSON.parse(this.plan.extra_data) : {};
        }
        
        const editValues = await fetchFromApi("GET", `/api/hcp/plans/${this.planId}/editvalues`, null, 
          {"cost_centers": true, "sup_orgs": true, "countries": true}
        );
        this.costCenters = editValues.cost_centers;
        this.supOrgs = editValues.sup_orgs;
        this.woltCountries = editValues.countries;
        this.oldPlans = JSON.parse(JSON.stringify(this.plan));

        // block refresh if changes were made
        // window.onbeforeunload = () => {
        //   if (JSON.stringify(this.allPlans) !== JSON.stringify(this.oldPlans)) {
        //     return true;
        //   }
        // }

        // unregister onbeforeunload when leaving the page
        this.$router.beforeEach((to, from, next) => {
          window.onbeforeunload = null;
          next();
        });
      },
      async beforeUnmount() {
        this.$root.appClass["page-helsinki"] = false;
      },
    }
  </script>
  
  <style scoped lang="scss">
  .data-table {
    margin-top: 0ex;

    tr {
      transition: all .2s ease-in-out;
    }

    tr.closed {
      color: #999;
    }

    .child-plan {
      background: #f6f6f6;
    }

    th {
      padding-top: 1.5em;
      height: 2.2em;
    }
  }
    
  .warn-info, .changes-saved-notice {
    background: var(--theme-accent);
    padding: 1.05em;
    margin-left: 1em;
    border-radius: 5px;
    margin-bottom: 1em;
    font-size: .8rem;
    font-weight: 600;
    color: #333;
    display: flex;
    align-items: center;
    width: auto;

    p {
      margin: 0;
    }

    i {
      margin-right: 1em;
    }
  }

  .changes-saved-notice {
    background: #4caf50;
    color: #fff;

    transition: all ease .2s;
    opacity: 0;
  }
  
  td input, td select, .listing-table input, .listing-table select {
    height: 3em;
    padding: 0 .5em;
    border: 1px solid #ddd;
    width: 500px;
  }

  .listing-table {
    dd, dt {
      padding-bottom: 1.5ex;
      padding-top: 1.5ex;
    }
  }

  .listing-table .ownerSelect, .listing-table .dropdown {
    width: 500px;
  }
  .listing-table select[multiple] {
    height: 7em;
  }
  td select {
    height: 3.5em; /* padding doesn't work with select */
  }

  .invalid-field, :required:placeholder-shown {
    background: #ffdddd;
    border: 1px solid #ff0000;
  }

  button:disabled {
    cursor: not-allowed;
  }

  button:disabled:focus {
    opacity: 1;
    transform: unset;
  }

  .ownerSelect {
    min-width: 200px;
  }
  </style>
