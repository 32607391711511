<template>
<main>
  <HeadingSubtitle text="Ticket automation" icon="fa-solid fa-bolt" />
  <hr />
  <!-- <section class="highlight">
    <p style="font-weight:bold">Status: <span style="color:green; display: inline-block; margin-left: 4.5em;border: 1px green solid;
      padding: 0.5ex 2ex;
      border-radius: 1ex;
      background-color: rgb(0 128 0 / 5%);">OK</span></p>
    <dl class="listing">
      <dt title="Last ticket processing activity">Last ticket activity:</dt><dd>2034-02-09 3:34 (45 minutes ago) <abbr title="there is a delay up to 3 minutes">*</abbr></dd>
      <dt title="Last ticket processing automation start">Last start:</dt><dd>2034-02-09 3:34 (45 minutes ago)</dd>
      <dt title="Estmiated next start of the ticket processing automation">Next start (estimated):</dt><dd>2034-02-09 3:34 (45 minutes ago)</dd>
      <!- <dt>Email:</dt><dd>{{user.email}}</dd>
      <dt>Team lead:</dt><dd><a :href="'/user/'+ user.teamlead_id">{{user.teamlead_name}}</a></dd>
      ->
    </dl>
  </section> -->
  <tabs @changed="this.activeTab = $event.tab.name" v-if="permissions.hasTicketsRead">
    <tab name="Failing tickets">
      <ErrorStateTickets v-if="activeTab == 'Failing tickets' || activeTab == ''" :active="activeTab == 'Failing tickets' || activeTab == ''" />
    </tab>     
    <tab name="Recent log">
      <RecentTicketLog v-if="activeTab == 'Recent log'" :active="activeTab == 'Recent log'" />
    </tab> 
    <tab name="Recent tickets">
      <RecentTickets v-if="activeTab == 'Recent tickets'" :active="activeTab == 'Recent tickets' " />
    </tab>
    <tab name="Last 24h" v-if="permissions.isIT">
      <TicketErrors24h v-if="activeTab == 'Last 24h'" />
    </tab>
    <!-- <tab name="Active tickets" v-if="permissions.isIT">
      <ActiveTicketsOverview />
    </tab> -->
  </tabs>
</main>
</template>

<script>
import {formatDateTime} from '@/services/AppTools.js'
import {permissions} from '@/services/Permissions.js'
import HeadingSubtitle from '@/components/objects/text/HeadingSubtitle.vue';
import RecentTickets from '@/views/tickets/RecentTickets.vue'
import RecentTicketLog from '@/views/tickets/RecentTicketLog.vue'
import TicketErrors24h from '@/views/tickets/TicketErrors24h.vue'
import ErrorStateTickets from '@/views/tickets/ErrorStateTickets.vue'
import {Tabs, Tab} from 'vue3-tabs-component';

export default {
  name: 'TicketView',
  components: {
    HeadingSubtitle,
    RecentTickets,
    RecentTicketLog,
    TicketErrors24h,
    ErrorStateTickets,
    Tabs, Tab,
  },
  data() {
    return {
      openTicketId: null,
      ticketLog: [],
      page: 0,
      permissions: permissions,
      formatDateTime: formatDateTime,
      activeTab: "",
    };
  },
}
</script>
