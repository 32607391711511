<template>
  <div>
    <h4>Tickets with automation state 'ERROR'</h4>
    <table class="data-table">
      <tr class="data-header">
        <th>Ticket</th>
        <th>Title</th>
        <th>Requester</th>
        <th>Requested for</th>
        <th>Date</th>
        <th>Ticket status</th>
        <th>Automation code</th>
        <th>Service form id</th>
        <th>Last error</th>
      </tr>
      <tr class="data-row" v-for="row in errorTickets" :key="row.id">
        <td><PortalLink view="ticket" :item="row.id">{{row.id}}</PortalLink></td>
        <td>{{row.title}}</td>
        <td><PortalLink view="user" :item="row.requester">{{row.requester}}</PortalLink></td>
        <td><PortalLink view="user" :item="row.requested_for">{{row.requested_for}}</PortalLink></td>
        <td>{{formatDateTime(row.date)}}</td>
        <td>{{row.status}}</td>
        <td>{{row.automation_code}}</td>
        <td>{{row.requestform_id}}</td>
        <td><button class="btn-new subtle" @click="showErrorForTicket = row">show error</button></td>
      </tr>
      <tr class="data-row nodata" v-if="errorTickets && errorTickets.length == 0">
        <td colspan="5">No error tickets found!</td>
      </tr>
    </table>

    <ModalDialog :title="showErrorForTicket.id + ' ' + showErrorForTicket.title" v-if="showErrorForTicket" @close="showErrorForTicket=null" class="wide-modal">
      <pre style="font-size: 13px;color: red;">
        {{ removeEmptyLines(showErrorForTicket.last_error) }}
      </pre>
    </ModalDialog>
  </div>
</template>

<script>
import {fetchFromApi, formatDateTime, registerInfiniteScoll, unregisterInfiniteScoll} from '@/services/AppTools.js'
import PortalLink from '@/components/PortalLink.vue';
import ModalDialog from '@/components/ModalDialog.vue'

export default {
  name: 'ErrorStateTickets',
  components: {
    PortalLink,
    ModalDialog
  },
  data() {
    return {
      errorTickets: [],
      formatDateTime: formatDateTime,
      page: 0,
      showErrorForTicket: null,
    };
  },
  methods: {
    async loadData(page = 0) {
      if(this.$root.loading) return; // one page load at a time
      this.$root.loading = true;
      this.errorTickets  = await fetchFromApi(this, `/api/ticket.errorstate`, null);
      this.$root.loading = false;
    },
    removeEmptyLines(input) {
      return input
        .split('\n') // Split the string into an array of lines
        .filter(line => line.trim() !== '') // Filter out lines that are empty or contain only whitespace
        .join('\n'); // Join the lines back into a single string
    }
  },
  async beforeMount() {
    this.loadData()
  },
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

td:nth-child(1) { // date column
  width: 10ex; // no text wrapping
}

td {
  color: red;
}

</style>

<style lang="scss">
.wide-modal {
  width: 80%!important;
  min-width: 700px!important;
  max-width: 1500px!important;
  max-height: 90%; // using 90vh makes dialog rendering blurry

  .details-modal-content {
    min-height: 200px;
  }
}
</style>