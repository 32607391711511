<template>
  <header v-if="planData" class="helsinki">
    <div> <!-- top row -->
      <!-- breadcrumb -->
      <menu class="breadcrumb">
        <ul><PortalLink view="hcplanning" class="bubble">Headcount Planning</PortalLink></ul>
        <ul v-if="planData.parent_plan"><a :href="`/hcplanning/${planData.parent_plan}`" class="bubble plan-label">{{ planData.parent_plan_title }}</a></ul>
        <ul><a href="#" @click="b=$event.target.getBoundingClientRect(); showPlanMeta={y:b.bottom, x:(b.left + (b.right - b.left) / 2) }" class="bubble plan-label">{{ planData.title || "Untitled plan" }} <i style="margin-left:1ex" class="fa-solid fa-circle-info"></i></a></ul>
        <span v-if="planData.status != 10" style="color: red">Not active</span>
      </menu>

      <!-- user notifications -->
      <div class="notifications">
        <!-- budget view -->
        <!-- <BudgetView v-if="planData.current_user.can_view_budget" :funds="funds" /> -->
      </div>
    </div>

    <div> <!-- bottom row -->
      <!-- tab navigation -->
      <nav>
        <div :class="{active: viewedPage === 'plan'}">
          <button class="btn-new subtle" @click="viewedPage = 'plan', editMode = false">Plan</button>
        </div>
        <!-- <div v-if="planData.current_user.can_view_current" :class="{active: viewedPage === 'current'}">
          <button class="btn-new subtle" @click="viewedPage = 'current', editMode = false">Current</button>
        </div> -->
        <div v-if="planData.current_user.can_approve_all_positions" :class="{active: viewedPage === 'approvals'}">
          <button class="btn-new subtle" @click="viewedPage = 'approvals', editMode = false" 
            :set="pendingApprovals = planPositions ? planPositions.filter(pos => pos.approval === 0 && pos.position_id).length : 0">
            Review approvals <span v-if="pendingApprovals" class="badge">{{ pendingApprovals }}</span>
          </button>
        </div>
        <div v-if="planData.current_user.is_admin" :class="{active: viewedPage === 'access'}">
          <button class="btn-new subtle" @click="viewedPage = 'access', editMode = false">Access</button>
        </div>
      </nav>
      
      <!-- text filter: plan -->
      <SearchBox placeholder="Filter positions" v-model="filter.fulltext" />

      <!-- view toolbar: plan -->
      <div class="toolbar" v-if="viewedPage =='plan' && !planData.error">
        <div class="hcp-plan-quickfilter" v-if="viewedPage =='plan'" >
          <button :class="quickFilterClass('all')" @click="setQuickFilter('all')" class="btn-new subtle slim">All</button>
          <button :class="quickFilterClass('filled')" @click="setQuickFilter('filled')"  class="btn-new subtle slim tooltip-above tt-small" data-text="Status = {FILLED, ON_HOLD, CANCELLED}">Filled</button>
          <button :class="quickFilterClass('ongoing')" @click="setQuickFilter('ongoing')"  class="btn-new subtle slim tooltip-above tt-small" data-text="SR Hired != Yes">Ongoing</button>
          <button :class="quickFilterClass('notstarted')" @click="setQuickFilter('notstarted')"  class="btn-new subtle slim tooltip-above tt-small" data-text="Status = NOT_STARTED and approved">Not started</button>
          <button :class="quickFilterClass('notapproved')" @click="setQuickFilter('notapproved')"  class="btn-new subtle slim tooltip-above tt-small" data-text="Approval = {PENDING, NOT_SUBMITTED}">Not approved</button>
        </div>

        <button v-if="!editMode" class="btn-new outline" @click="b=$event.target.getBoundingClientRect(); showExportDropdown={y:b.bottom, x:(b.left + (b.right - b.left) / 2) }"><i class="fa-solid fa-file-csv"></i>Export plan</button>
        <div v-if="showExportDropdown" class="export-menu" v-click-outside="closeExportCsvMenu" :style="{top: `${showExportDropdown.y}px`, left: `${showExportDropdown.x}px`}">
          <button class="btn-new outline" @click="sendCSVExportRequest()"><i class="fa-solid fa-file-csv"></i>For Excel</button>
          <button class="btn-new outline" @click="sendCSVExportRequest('Mac')"><i class="fa-solid fa-file-csv"></i>For Mac</button>
        </div>
        
        <!-- <button v-if="!editMode" class="btn-new outline" @click="sendCSVExportRequest()"><i class="fa-solid fa-file-csv"></i>Export plan</button> -->
        <button v-if="!editMode && (planData.current_user.can_view_edit_mode)" 
          class="btn-new"  @click="toggleEdit()"><i class="fa-solid fa-pen-to-square"></i>Edit</button>
        
        <button v-if="editMode && planData.current_user.can_add_position" 
          class="btn-new outline" @click="addAnEmptyPosition"><i class="fa-solid fa-plus"></i>Add position</button>
        <button v-if="editMode" class="btn-new outline" @click="applyEdit()"><i class="fa-solid fa-save"></i>Save changes</button>
        <button v-if="editMode" class="btn-new" @click="toggleEdit()"><i class="fa-solid fa-x"></i>Cancel edit</button>
      </div>
      <!-- view toolbar: subplans -->
      <div class="toolbar" v-if="viewedPage == 'subplans'">  
        <button v-if="planData.current_user.is_admin" title="You can create more subplans in the admin panel" class="btn-new" 
          @click="this.$router.push(`/hcplanning/admin/${planId}/new-subplan`)">Add subplan</button>
      </div>
    </div>
  </header>
  <!-- error (403, etc) -->
  <main v-if="planData !== null && planData.error" class="helsinki">
    <pre style="padding-top: 1em; font-size: 24px; color: rgb(224, 30, 90);"> {{planData.error}}</pre>
  </main>
  <main v-if="planData !== null && !planData.error">
    <!-- Plan view -->
    <div class="positions-table" style="margin-top: 63px" v-if="planPositions !== null && viewedPage === 'plan'">
      <div class="pos-table-data">
        <table class="data-table" :set="ppos = ((viewedPage === 'plan') ? filteredPlanPositions : applyFilter(planPositions))"  :class="{edit: editMode}" ref="positionsDataTable">
          <thead>
            <tr class="data-header">
              <th :class="sortClass('plan_name')" @click="setSort('plan_name')">Main Plan</th>
              <th :class="sortClass('position_id')" @click="setSort('position_id')">Position ID</th>
              <th :class="sortClass('approval')" @click="setSort('approval')">Approval</th>
              <th class="sr-synced" :class="sortClass('sr_status')" @click="setSort('sr_status')" title="Smartrecruiters Status">Status</th>
              <th :class="sortClass('target_start')" @click="setSort('target_start')">Target Start<br> Date</th>
              <th :class="sortClass('extra_data.estimated_start')" @click="setSort('extra_data.estimated_start')">Estimated Start<br> Date</th>
              <th class="job-title" :class="sortClass('extra_data.job_title')" @click="setSort('extra_data.job_title')">Job Title</th>
              <th class="job-location" :class="sortClass('job_profile_name')" @click="setSort('job_profile_name')">Job Profile</th>
              <th class="job-location" :class="sortClass('job_location_name')" @click="setSort('job_location_name')">Job location /<br>Job country</th>
              <th :class="sortClass('extra_data.employee_type_name')" @click="setSort('extra_data.employee_type_name')">Employee<br>Type</th>
              <th :class="sortClass('extra_data.fte')" @click="setSort('extra_data.fte')">FTE (%)</th>
              <!-- <th :class="sortClass('priority')" @click="setSort('priority')">Priority</th> -->
              <th class="hiring-manager" :class="sortClass('hiring_manager_name')" @click="setSort('hiring_manager_name')">Hiring Manager</th>
              <th :class="sortClass('recruiter')" @click="setSort('recruiter')">Recruiter</th>
              <th :class="sortClass('extra_data.is_backfill')" @click="setSort('extra_data.is_backfill')">Backfill</th>
              <th :class="sortClass('extra_data.backfill')" @click="setSort('extra_data.backfill')">Backfill for</th>
              <th class="job-location" :class="sortClass('extra_data.cost_center')" @click="setSort('extra_data.cost_center')">Cost Center</th>
              <th :class="sortClass('extra_data.entity')" @click="setSort('extra_data.entity')">Entity</th>
              <th :class="sortClass('extra_data.funding_source')" @click="setSort('extra_data.funding_source')">Funding <br>Source</th>
              <th :class="sortClass('extra_data.team_name')" @click="setSort('extra_data.team_name')">Team name</th>
              <th :class="sortClass('extra_data.group')" @click="setSort('extra_data.group')">Group</th>
              <th :class="sortClass('extra_data.skill')" @click="setSort('extra_data.skill')">Skill</th>
              <th class="job-location">Hiring Mananger Comments</th>
              <th class="job-location">Approver Comments</th>
              <th :class="sortClass('created')" @click="setSort('created')">Position<br>Created</th>
              <th title="Synced with Smartrecruiters" class="sr-synced" :class="sortClass('sr_data.hired')" @click="setSort('sr_data.hired')">SR Hired</th>
              <th title="Synced with Smartrecruiters" class="sr-synced" :class="sortClass('sr_data.position_open_date')" @click="setSort('sr_data.position_open_date')">Position Open <br>Date</th>
              <th title="Synced with Smartrecruiters" class="sr-synced" :class="sortClass('actual_start')" @click="setSort('actual_start')">Actual Start <br>Date</th>
              <th title="Synced with Smartrecruiters" class="sr-synced" :class="sortClass('sr_data.hire_name')" @click="setSort('sr_data.hire_name')">Hire name</th>
              <th class="sr-synced" :class="sortClass('extra_data.last_sync')" @click="setSort('extra_data.last_sync')">Last Sync</th>
              <!-- <th>Salary range max</th>
                <th>Salary range min</th> -->
              <!-- <th :class="sortClass('extra_data.tags')" @click="setSort('extra_data.tags')">Tags</th> -->
            </tr>
          </thead>
          <tbody v-if="ppos && ppos.length">
            <tr class="data-row" v-for="pos in ppos" :key="pos.id" :data-id="pos.id" :class="[pos._class, pos._dirty ? 'dirty': '']"
              :set="rowEdit = editMode && pos.pos_acs.can_edit">
              
              <!-- Main plan name -->
              <td>{{ pos.plan_name || pos.plan }}</td>
              
              <!-- position id -->
              <td class="pid">{{ pos.position_id }}</td>

              <!-- approval status -->
              <td v-if="!editMode && pos.approval == 10 && pos.pos_acs.can_edit">
                <span v-if="pos.extra_data.hint" style="z-index: 99; color:#f44336" :data-text="pos.extra_data.hint" class="tooltip"><i class="fa-solid fa-circle-info" style="margin-right: 1ex;"></i></span>
                <button :set="isValid = validatePosition(pos)" class="btn-new slim" :data-text="isValid ? '':'Required fields are missing!'"
                  :class="{'tooltip':!isValid}"
                  :disabled="!isValid"
                  @click="submitForApprovalDialog = pos;">Submit</button>
              </td>
              <td v-else-if="!rowEdit || !pos.pos_acs.can_edit_approval">
                {{ approvalToWord(pos.approval) }}
              </td>
              <td v-else>
                <select v-model="pos.approval">
                  <option value="10">Not requested</option>
                  <option value="0">Pending</option>
                  <option value="-1">Rejected</option>
                  <option value="1">Approved</option>
                  <option value="11">2025 incremental</option>
                </select>
              </td>

              <!-- Smartrecruiters status -->
              <td>
                <a v-if="pos.sr_data.sr_job_id" :href="`https://www.smartrecruiters.com/app/jobs/details/${pos.sr_data.sr_job_id}/details`" target="_blank">{{ statusToWord(pos.sr_data.sr_status) }}</a>
                <span v-else>{{ statusToWord(pos.sr_data.sr_status) }}</span>
              </td>

              <!-- Target Start -->
              <td v-if="!rowEdit || isReadonly(pos, 'target_start')" :class="required(pos.planned_quarter)">{{ formatDate(pos.planned_quarter) }}</td><!-- TODO: rename underlying field -->
              <td v-else :class="required(pos.planned_quarter)"><input type="date" v-model="pos.planned_quarter" placeholder="Target start date" :min="dayjs().add(1, 'month').format('YYYY-MM-DD')" /></td>

              <!-- Estimated Start -->
              <td v-if="!rowEdit || isReadonly(pos, 'estimated_start')">{{ formatDate(pos.extra_data ? pos.extra_data.estimated_start : '') }}</td>
              <td v-else><input type="date" v-model="pos.extra_data.estimated_start" placeholder="Estimated start date" /></td>

              <!-- Job title -->
              <td v-if="!rowEdit" :class="required(pos.extra_data.job_title)">{{ pos.extra_data ? pos.extra_data.job_title : '' }}</td>
              <td v-else :class="required(pos.extra_data.job_title)"><input type="text" v-model="pos.extra_data.job_title" placeholder="Job title" /></td>

              <!-- Job profile -->	
              <td v-if="!rowEdit" class="job-profile" :class="required(pos.job_profile)">{{ pos.job_profile_name }}</td>
              <td v-else :class="required(pos.job_profile)">
                <FilterDropdown :value="pos.job_profile" :text="pos.job_profile_name" :items="editValues.job_profiles" 
                  @select="pos.job_profile = $event.id; pos.job_profile_name = $event.value" />
              </td>

              <td v-if="!rowEdit" :class="required(pos.job_location_name)">{{ pos.job_location_name }}</td>
              <td v-else :class="required(pos.job_location_name)">
                <FilterDropdown :value="pos.job_location" :text="pos.job_location_name" :items="editValues.locations.concat(editValues.countries)" 
                  @select="pos.job_location = $event.id; pos.job_location_name = $event.value" />
              </td>

              <!-- Employee type -->
              <td v-if="!rowEdit" :class="required(pos.extra_data.employee_type)">{{ pos.extra_data.employee_type_name }}</td>
              <td v-else :class="required(pos.extra_data.employee_type)">
                <FilterDropdown :value="pos.extra_data.employee_type" :text="pos.extra_data.employee_type_name" :items="editValues.employee_types" 
                  @select="pos.extra_data.employee_type = $event.id; pos.extra_data.employee_type_name = $event.value" />
              </td>

              <!-- FTE -->
              <td v-if="!rowEdit">{{ pos.extra_data.fte }}</td>
              <td v-else><input type="number" min="0" max="100" v-model="pos.extra_data.fte" placeholder="FTE" /></td>

              <!-- <td v-if="!rowEdit">{{ mapPriority(pos.priority) }}</td>
              <td v-else>
                <select v-model="pos.priority">
                  <option value="1">1 - Critical</option>
                  <option value="2">2 - High</option>
                  <option value="3">3 - Medium</option>
                  <option value="4">4 - Low</option>
                </select>
              </td> -->

              <td v-if="!rowEdit" :class="required(pos.hiring_manager)">{{ pos.hiring_manager_name }}</td>
              <td v-else-if="!planData.current_user.can_edit_all_positions" :class="required(pos.hiring_manager)">
                <SelectUserInput :value="pos.hiring_manager" :text="pos.hiring_manager_name" @select="pos.hiring_manager = $event.id; pos.hiring_manager_name = $event.name" 
                  :dataUrl="'/api/hcp/plans/' + planId + '/hiringmanagers?limit_sup_org=true'" :dataCacheKey="'hcp_hiringmanagers_for_' + planData.current_user.id"/>
              </td>
              <td v-else :class="required(pos.hiring_manager)">
                <SelectUserInput :value="pos.hiring_manager" :text="pos.hiring_manager_name" @select="pos.hiring_manager = $event.id; pos.hiring_manager_name = $event.name" 
                  :dataUrl="'/api/hcp/plans/' + planId + '/hiringmanagers'" dataCacheKey="hcp_hiringmanagers"/>
              </td>
              
              <td v-if="!rowEdit">{{ pos.recruiter_name }}</td>
              <td v-else>
                <SelectUserInput :value="pos.recruiter" :text="pos.recruiter_name" @select="pos.recruiter = $event.id; pos.recruiter_name = $event.name" 
                  :dataUrl="`/api/hcp/plans/${planId}/recruiters`" dataCacheKey="hcp_recruiters"/>
              </td>

              <td v-if="!rowEdit" :class="required(pos.extra_data.is_backfill)">{{ pos.extra_data.is_backfill }}</td>
              <td v-else :class="required(pos.extra_data.is_backfill)">
                <select v-model="pos.extra_data.is_backfill">
                  <option value=""></option>
                  <option value="No">No</option>
                  <option value="Yes">Yes</option>
                </select>
              </td>

              <td v-if="!rowEdit">{{ pos.extra_data.backfill_name }}</td>
              <td v-else>
                <SelectUserInput :value="pos.extra_data.backfill" :text="pos.extra_data.backfill_name" 
                  @select="pos.extra_data.backfill = $event.id; pos.extra_data.backfill_name = $event.name" 
                  :dataUrl="`/api/hcp/plans/${planId}/backfill`" dataCacheKey="hcp_backfill"/>
              </td>

              <!-- Cost center -->
              <td v-if="!rowEdit || isReadonly(pos, 'cost_center')" :class="required(pos.extra_data.cost_center_name)">{{ pos.extra_data.cost_center_name }}</td>
              <td v-else :class="required(pos.extra_data.cost_center_name)">
                <FilterDropdown :value="pos.extra_data.cost_center" :text="pos.extra_data.cost_center_name" :items="editValues.cost_centers" 
                  @select="pos.extra_data.cost_center = $event.id; pos.extra_data.cost_center_name = $event.value" />
              </td>

              <!-- Entity -->
              <td v-if="!rowEdit">{{ pos.extra_data.entity }}</td>
              <td v-else>
                <FilterDropdown :value="pos.extra_data.entity_id" :text="pos.extra_data.entity" :items="editValues.entities" 
                  @select="pos.extra_data.entity_id = $event.id; pos.extra_data.entity = $event.value" />
              </td>

              <!-- Funding source -->
              <td v-if="!rowEdit">{{ pos.extra_data.funding_source }}</td>
              <td v-else>
                <FilterDropdown :value="pos.extra_data.funding_source_id" :text="pos.extra_data.funding_source" :items="editValues.funding_sources" 
                  @select="pos.extra_data.funding_source_id = $event.id; pos.extra_data.funding_source = $event.value" />
              </td>

              <!-- Team name -->
              <td v-if="!rowEdit">{{ pos.extra_data ? pos.extra_data.team_name : '' }}</td>
              <td v-else><input type="text" v-model="pos.extra_data.team_name" placeholder="Team name" /></td>

              <!-- Group -->
              <td v-if="!rowEdit">{{ pos.extra_data ? pos.extra_data.group : '' }}</td>
              <td v-else><input type="text" v-model="pos.extra_data.group" placeholder="Group" /></td>

              <!-- Skill -->
              <td v-if="!rowEdit">{{ pos.extra_data ? pos.extra_data.skill : '' }}</td>
              <td v-else><input type="text" v-model="pos.extra_data.skill" placeholder="Skill" /></td>

              <!-- hiring mananger comments -->
              <td v-if="!rowEdit" class="comments" @mousemove="showComment($event, pos, false, 'hiringmanager', pos.extra_data.comments)" @mouseleave="hideComment($event, pos)">
                {{ pos.extra_data.comments }}
              </td>
              <td v-else class="comments" @click="showComment($event, pos, true, 'hiringmanager', pos.extra_data.comments)">
                <div class="comments-edit-td">{{ pos.extra_data.comments }}</div>
              </td>

              <!-- approver comments -->
              <td v-if="!rowEdit" class="comments" @mousemove="showComment($event, pos, false, 'approver', pos.extra_data.approver_comments)" @mouseleave="hideComment($event, pos)">
                {{ pos.extra_data.approver_comments }}
              </td>
              <td v-else class="comments" @click="showComment($event, pos, true, 'approver', pos.extra_data.approver_comments)">
                <div class="comments-edit-td">{{ pos.extra_data.approver_comments }}</div>
              </td>

              <!-- position created  -->
              <td class="sr-synced">{{ formatDateTime(pos.created) }}</td>

              <!-- Smartrecruiters data -->
              <td class="sr-synced">{{ pos.sr_data.hired ? 'Yes' : '' }}</td>
              <td class="sr-synced">{{ formatDate(pos.sr_data ? pos.sr_data.position_open_date : '') }}</td>
              <td class="sr-synced">{{ formatDate(pos.sr_data.target_start) }}</td>
              <td class="sr-synced">{{ pos.sr_data ? pos.sr_data.hire_name : '' }}</td>
              <td class="sr-synced">{{ formatDateTime(pos.last_sync) }}</td>
              <!-- <td>{{ pos.salary_min }} {{ pos.salary_currency }}</td>
              <td>{{ pos.salary_max }} {{ pos.salary_currency }}</td> -->
              <!-- <td v-if="!rowEdit">{{ pos.extra_data.tags }}</td>
              <td v-else><input type="text" v-model="pos.extra_data.tags" placeholder="Tags" /></td> -->

              <!-- actions -->
              <td class="actions-td">
                <!-- <div class="not-on-hover"><i class="fa-solid fa-ellipsis-vertical" style="padding-right: 1em"></i></div> -->
                <div class="onhover">
                  <button @click="lockTargetStart(pos)" title="Lock target start" class="btn-new subtle-icon compact" v-if="editMode && pos.pos_acs.can_approve && !isReadonly(pos, 'target_start')">
                    <i class="fa-solid fa-lock"></i></button>
                  <button class="btn-new subtle-icon compact" title="Delete position" v-if="rowEdit && pos.pos_acs.can_delete"
                    @click="event => toggleMarkForDeletion(pos, event)">
                    <i class="fa-solid fa-trash"></i></button>
                  <button title="View history" @click="historyDialog=pos" class="btn-new subtle-icon compact">
                    <i class="fa-solid fa-clock"></i></button>
                  <button title="Duplicate position" v-if="editMode && planData.current_user.can_add_position"
                    @click="pos.__dupCount = 1; duplicateDialog = pos" class="btn-new subtle-icon compact">
                    <i class="fa-regular fa-clone"></i></button>
                </div>
              </td>
            </tr>
          </tbody>
          <tr v-else>
            <td v-if="viewedPage == 'plan'" colspan="4" class="no-subplans-notice">No planned positions.</td>
            <td v-else colspan="4" class="no-subplans-notice">No approvals pending.</td>
          </tr>
        </table>
      </div>
    </div>

    <!-- Current positions view -->
    <div class="current-positions-table" style="margin-top: 63px" v-if="viewedPage === 'current'">
      <div class="">
        <table class="data-table current-positions">
          <thead>
            <tr class="data-header">
              <th>Position ID</th>
              <th>Person</th>
              <th>Status</th>
              <th>Availibility Quarter</th>
              <th colspan="2">Manager</th>
            </tr>
          </thead>
          <tbody v-if="currentPositions && currentPositions.length">
            <tr class="data-row" v-for="pos in currentPositions" :key="pos.id">
              <td>{{ pos.position_id }}</td>
              <td><RouterLink :to="`/user/${pos.worker}`">{{ pos.worker }}</RouterLink></td>
              <td>{{ statusToWord(pos.status) }}</td>
              <td>{{ translateDateToQuarter(pos.planned_quarter) }}</td>
              <td>{{ pos.hiring_manager }}</td>
              <td class="actions-td" style="background-color: white;">
                <button title="View history" @click="historyDialog=pos" class="btn-new subtle-icon compact"><i class="fa-solid fa-clock"></i></button>
              </td>
            </tr>
          </tbody>
          <tr v-else>
            <td colspan="6" class="no-subplans-notice">No current positions.</td>
          </tr>
        </table>
      </div>
    </div>

    <!-- Subplans view -->
    <SubPlans v-if="viewedPage === 'subplans'" style="margin-top: 63px" :planId="planId" :planData="planData"/>

    <!-- approvals view -->
    <Approvals v-if="viewedPage == 'approvals'" :approvals="applyFilter(planPositions)" 
      can_approve_position="true" :filter="filter" 
      double_row_header_mode="true" no_plan_column="true"/>

    <!-- access view -->
    <PlanAccess v-if="viewedPage == 'access'" :planId="planId" />

    <!-- modal dialogs and popouts -->
    <!-- comment popup -->
    <div class="plan-meta hcp-popout" v-click-outside="closeCommentPopup" v-if="showCommentPopup" :style="{top: `${showCommentPopup.y}px`, left: `${showCommentPopup.x}px`}">
      <div class="popout-content" style="min-width: 250px; display:grid; padding: 2px; box-sizing: border-box; grid-template-columns: 1fr auto; font-size:13px;">
        <template v-if="showCommentPopup.edit">
          <textarea v-if="showCommentPopup.datafield == 'hiringmanager'" class="comments-edit" v-model="showCommentPopup.pos.extra_data.comments" focus></textarea>
          <textarea v-if="showCommentPopup.datafield == 'approver'" class="comments-edit" v-model="showCommentPopup.pos.extra_data.approver_comments" focus></textarea>
        </template>
        <template v-else>
          <pre style="max-width: 80ex; white-space: pre-wrap; font-family: roboto;">{{ showCommentPopup.comment }}</pre>
        </template>
        
      </div>
    </div>

    <!-- plan meta data popup -->
    <div class="plan-meta hcp-popout" v-click-outside="closeMetaDataPopup" v-if="planData.owner && showPlanMeta" :style="{top: `${showPlanMeta.y}px`, left: `${showPlanMeta.x}px`}">
      <div class="popout-content" style="display:grid; padding: 1em; box-sizing: border-box; grid-template-columns: 1fr auto;">
        <div>
          Plan owner: <RouterLink v-if="permissions.hasUsersRead" :to="'/user/' + planData.owner">{{ planData.ownerLabel}}</RouterLink>
              <span v-else>{{ planData.ownerLabel}}</span>
        </div>
        <div v-if="planData.parent_plan">
          a subplan of <RouterLink :to="'/hcplanning/' + planData.parent_plan">{{ planData.parent_plan }}</RouterLink>
        </div>
        <button v-if="planData.current_user.is_admin" style="grid-column-start: 2; grid-row-start: 1;" title="Manage plan in the admin panel" class="btn-new" 
          @click="this.$router.push('/hcplanning/admin/' + planId)"><i class="fa-solid fa-gear"></i>Manage plan</button>

        <div style="grid-column-start: 1; grid-row-start: 2;">
          My plan roles:
          <div class="role-list"><template v-for="role in Object.keys(planData.current_user)" :key="role">
            <span class="permission" v-if="(role.startsWith('can_'))  && planData.current_user[role]">{{role}}</span>
            <span v-if="(role.startsWith('is_')) && planData.current_user[role]">{{role}}</span>
          </template></div>
        </div>
      </div>
    </div>

    <ModalDialog :title="'Submit for approval: ' + submitForApprovalDialog.position_id" v-if="submitForApprovalDialog" @close="submitForApprovalDialog=null">
      <p style="font-size: 15px">Update your position comment/justification for the approver:</p>

      <div style="font-size: 11px;font-weight: bold;">HIRING MANANGER COMMENTS</div>
      <textarea v-model="submitForApprovalDialog.extra_data.comments" focus style="font-size: 15px; font-family: Roboto;width: 100%; height: 15em;"></textarea>

      <template v-slot:buttons>
        <button class="btn-new" :disabled="this.$root.loading" @click="submitForApproval(submitForApprovalDialog)">Submit</button>
        <button class="btn-new outline" :disabled="this.$root.loading" @click="submitForApprovalDialog=null">Cancel</button>
      </template>
    </ModalDialog>

    <ModalDialog title="Duplicate position" v-if="duplicateDialog" @close="duplicateDialog=null">
      <p>Create duplicates of position: <b>{{ duplicateDialog.position_id }}</b><br>
        <i>{{ duplicateDialog.job_family_name }} {{ duplicateDialog.job_level_name }}<br>
        {{ duplicateDialog.job_location_name }}</i></p>
      <p>Number of duplicates: <input class="numinput" type="number" min="1" max="100" v-model="duplicateDialog.__dupCount" style="width: 4em; margin-left: 1em" /> </p>

      <template v-slot:buttons>
        <button class="btn-new" :disabled="this.$root.loading" @click="duplicate(duplicateDialog, duplicateDialog.__dupCount)">Create duplicates</button>
        <button class="btn-new outline" :disabled="this.$root.loading" @click="duplicateDialog=null">Cancel</button>
      </template>
    </ModalDialog>

    <ModalDialog title="Position history" v-if="historyDialog" @close="historyDialog=null" class="hcp-history-modal">
      <PositionHistory :planId="planId" :positionId="historyDialog.id"> </PositionHistory>

      <template v-slot:buttons>
        <button class="btn-new outline" @click="historyDialog=null">Close</button>
      </template>
    </ModalDialog>

    <!-- <ModalDialog title="Lock target start" v-if="lockTargetStartDialog" @close="lockTargetStartDialog=null">
      <p>Are you sure you want to lock the target start field for posistion: <b>{{ lockTargetStartDialog.position_id }}</b></p>
      <template v-slot:buttons>
        <button class="btn-new" :disabled="this.$root.loading" @click="lockTargetStart(lockTargetStartDialog)">Lock target start</button>
        <button class="btn-new outline" :disabled="this.$root.loading" @click="lockTargetStartDialog=null">Cancel</button>
      </template>
    </ModalDialog> -->

  </main>
</template>

<script>
import HeadingSubtitle from "@/components/objects/text/HeadingSubtitle.vue";
import GenericButton from "@/components/objects/buttons/GenericButton.vue";
import PositiveButton from "@/components/objects/buttons/PositiveButton.vue";
import { fetchFromApi, postToApi, putToApi, deleteFromApi, downloadFileFromApi, formatDate, formatDateTime } from '@/services/AppTools.js';
import { permissions } from "@/services/Permissions";
import SelectUserInput from "@/components/objects/inputs/SelectUserInput.vue";
import FilterDropdown from "@/components/objects/inputs/FilterDropdown.vue";
import PositionHistory from '@/views/hcplanning/PlanHistoryView.vue'
import ModalDialog from '@/components/ModalDialog.vue'
import PortalLink from '@/components/PortalLink.vue'
import SearchBox from '@/views/hcplanning/components/SearchBox.vue';
import Approvals from '@/views/hcplanning/components/Approvals.vue'
import SubPlans from '@/views/hcplanning/components/SubPlans.vue'
import PlanAccess from '@/views/hcplanning/components/PlanAccess.vue'
import BudgetView from '@/views/hcplanning/components/BudgetView.vue'
import dayjs from "dayjs";

export default {
  name: 'HcpPlanView',

  components: {
    // HeadingSubtitle,
    // GenericButton,
    // PositiveButton,
    PositionHistory,
    SelectUserInput,
    ModalDialog,
    FilterDropdown,
    PortalLink,
    SearchBox,
    Approvals,
    PlanAccess,
    // BudgetView,
    SubPlans
  },

  data() {
    return {
      planId: this.$route.params.id,
      planData: null,
      allPositions: null,
      planPositions: null,
      filteredPlanPositions: null,
      currentPositions: null,
      pendingApprovals: 0,
      editValues: null,
      filter: {
        srStatus: null,
        showPending: true,
        showApproved: true,
        quickfilter: "all",
      },
      funds: {
        total: 0,
        current: 0,
        currentSideCosts: 0,
        approved: 0,
        approvedSideCosts: 0,
        pending: 0,
        pendingSideCosts: 0,
      },

      undoApproval: [],
      editToDelete: [],
      usersDataCache: {},
      permissions,

      editMode: false,  // view || edit
      viewedPage: this.$route.params.view || "plan", // plan || current

      sortcolumn: "position_id",
      sortOrder: 1,

      showExportDropdown: false,
      // modal dialogs
      duplicateDialog: null,
      historyDialog: null,
      lockTargetStartDialog: null,
      showPlanMeta: false,
      showCommentPopup: false,
      submitForApprovalDialog: null,

      dayjs: dayjs,
      formatDate: formatDate, 
      formatDateTime: formatDateTime,
    };
  },
  watch: {
    // whenever filter changes, this function will run
    filter: {
      handler(newValue, oldValue) {
        // Note: `newValue` will be equal to `oldValue` here
        // on nested mutations as long as the object itself
        // hasn't been replaced.

        // re-apply filter. Use manual re-apply for better UX.
      this.filteredPlanPositions = this.applyFilter(this.planPositions);
      },
      deep: true
    },
  },

  methods: {
    handleChange (newVal, oldVal) {
      // NOTE: For mutated objects, newVal and oldVal will be identical.
      newVal._dirty = true;
    },
    isReadonly(pos, field) {
      return pos.pos_acs && pos.pos_acs["readonly_fields"] && pos.pos_acs["readonly_fields"].includes(field);
    },
    required(value) {
      // return value !== null && value !== undefined && value !== "";
      const isEmpty = !value || value == ''
      return {'required': isEmpty};
    },
    validatePosition(pos) {
      const allRequired = [
        pos.planned_quarter,
        pos.extra_data.job_title,
        pos.job_profile,
        pos.job_location_name,
        pos.hiring_manager,
        pos.extra_data.is_backfill,
        pos.extra_data.cost_center_name,
      ];

      return allRequired.every(field => field !== null && field !== undefined && field !== "");
    },
    lockTargetStart(pos) {
      pos.extra_data.target_start_locked = true;
      pos.pos_acs.readonly_fields.push("target_start");
      this.lockTargetStartDialog = null;
    },
    quickFilterClass(name) {
      return this.filter.quickfilter === name ? "active": null;
    },
    setQuickFilter(name) {
      this.filter.quickfilter = name;
      this.filteredPlanPositions = this.applyFilter(this.planPositions);
    },
    closeExportCsvMenu() {
      // event is triggered the first time when the popup opens
      if(this.showExportDropdown.closeOnClick){
        this.showExportDropdown = null;
      } else {
        this.showExportDropdown.closeOnClick = true;
      }
    },
    closeMetaDataPopup() {
      // event is triggered the first time when the popup opens
      if(this.showPlanMeta.closeOnClick){
        this.showPlanMeta = null;
      } else {
        this.showPlanMeta.closeOnClick = true;
      }
      // console.log("closeMetaDataPopup")
    },
    closeCommentPopup() {
      // event is triggered the first time when the popup opens
      if(this.showCommentPopup.closeOnClick){
        this.showCommentPopup = null;
      } else {
        this.showCommentPopup.closeOnClick = true;
      }
      // console.log("closeMetaDataPopup")
    },
    sortClass(field) {
      if (this.sortcolumn === field) {
        if (this.sortOrder === 1)
          return ["sortable", "asc"];
        return ["sortable", "desc"];
      }
      return "sortable";
    },
    setSort(field) {
      if (this.sortcolumn === field) {
        this.sortOrder *= -1;
      } else {
        this.sortcolumn = field;
        this.sortOrder = 1;
      }

      this.filteredPlanPositions = this.applyFilter(this.planPositions);
    },
    applyFilter(positions) {
      let filtered = null;

      if (this.viewedPage == "approvals") {
        filtered = positions.filter(pos => pos.approval === 0 && pos.position_id);
      } else {
        filtered = positions.filter(pos => {
          // sr_status is number, filter.srStatus is text
          if (this.filter.srStatus && pos.sr_data.sr_status != this.filter.srStatus) {
            return false;
          }

          if (!this.filter.showPending && pos.approval === 0) {
            return false;
          }

          if (!this.filter.showApproved && pos.approval === 1) {
            return false;
          }

          if (this.filter.quickfilter === "filled" && !(pos.sr_data.sr_status >= 5)) { // FILLED
            return false;
          }
          if (this.filter.quickfilter === "ongoing" && pos.sr_data.hired === true) {
            return false;
          }
          if (this.filter.quickfilter === "notstarted"  && !(pos.approval !== 0 && (pos.sr_data.sr_status == 0 || pos.sr_data.sr_status == null))) {
            return false;
          }
          if (this.filter.quickfilter === "notapproved" && (pos.approval !== 0 && pos.approval !== 10)) {
            return false;
          }

          if (this.filter.fulltext) {
            let found = false;
            for (const [key, value] of Object.entries(pos)) {
              if(typeof value === 'string' && value.toLowerCase().includes(this.filter.fulltext.toLowerCase())){
                found = true;
                break;
              }
            }
            if(!found) {
              for (const [key, value] of Object.entries(pos.extra_data)) {
                if(typeof value === 'string' && value.toLowerCase().includes(this.filter.fulltext.toLowerCase())){
                  found = true;
                }
              }
            }
            if(!found){
              return false;
            }
          }
          return true;
        });
      }

      // sort data
      if (!this.sortcolumn){
        return filtered;
      }

      if(this.sortcolumn.includes("extra_data")) {
        let extraDataField = this.sortcolumn.split(".")[1];
        return filtered.sort((a, b) => {
          if (a._class == "unsaved") return 1; // new items are always at the bottom
          if (a.extra_data[extraDataField] === undefined || a.extra_data[extraDataField] === null || a.extra_data[extraDataField] == "") return this.sortOrder;
          
          if (b._class == "unsaved") return 1; // new items are always at the bottom
          if (b.extra_data[extraDataField] === undefined || b.extra_data[extraDataField] === null || b.extra_data[extraDataField] == "") return -this.sortOrder;

          return a.extra_data[extraDataField] > b.extra_data[extraDataField] ? 1 * this.sortOrder : -1 * this.sortOrder;
        });
      }

      return filtered.sort((a, b) => {
        if (a._class == "unsaved") return 1; // new items are always at the bottom
        if (a[this.sortcolumn] === null || a[this.sortcolumn] == "") return this.sortOrder;
        if (b._class == "unsaved") return 1; // new items are always at the bottom
        if (b[this.sortcolumn] === null || b[this.sortcolumn] == "") return -this.sortOrder;
        return a[this.sortcolumn] > b[this.sortcolumn] ? 1 * this.sortOrder : -1 * this.sortOrder;
      });
    },
    async fetchPlan() {
      let planID = this.$route.params.id;
      this.planData = await fetchFromApi("GET", `/api/hcp/plans/${planID}`);
      document.title = this.planData.title + " - Headcount Planning";
      
      if (permissions && this.planData.owner === permissions.user.id) {
        this.planData.ownerLabel = "you";
      } else {
        this.planData.ownerLabel = ((this.planData.owner_first_name ? (`${this.planData.owner_first_name} ${this.planData.owner_last_name}`) : false)
        || this.planData.owner
        || "Unspecified");
      }
      
      if (!this.planData.extra_data) {
        this.planData.extra_data = {};
      } else {
        this.planData.extra_data = JSON.parse(this.planData.extra_data);
      }
      
      if (this.planData.error) {
        return;
      }

      this.allPositions = await fetchFromApi("GET", `/api/hcp/plans/${planID}/positions`);

      // JSON parse all extra_data fields in positions
      // ignore if extra_data is null
      this.allPositions.forEach(pos => {
        pos.extra_data = pos.extra_data ? JSON.parse(pos.extra_data) : {};
        pos.sr_data = pos.sr_data ? JSON.parse(pos.sr_data) : {};
      });
      
      // Watch changes in all positions. Must come after we parse the extra data
      // this.allPositions = allPositions2.map(pos => new Proxy(pos, proxyHandler));
      this.allPositions.forEach((val) => {
        this.$watch(() => val, this.handleChange, {deep: true});
      });

      this.planPositions = this.allPositions.filter(pos => pos.worker == null);
      this.currentPositions = this.allPositions.filter(pos => pos.worker != null);

      this.funds.total = this.planData.extra_data.total_funds || 10000;

      // current fund is the sum of all current positions
      // TODO: before salary implementation in the API, use 1000 as fallback

      function calcCost(positions) { 
        return  positions.reduce((acc, pos) => acc + (((pos.salary_min || 1000) + (pos.salary_max || 1000)) / 2) * (1 + (pos.side_cost_factor || 0.2)), 0);
      }

      function calcSideCost(positions) { 
        return  positions.reduce((acc, pos) => acc + (((pos.salary_min || 1000) + (pos.salary_max || 1000)) / 2) * (pos.side_cost_factor || 0.2), 0);
      }

      this.funds.current = Number(this.planData.extra_data.actuals || 0);
      this.funds.currentSideCosts = 0;
      // this.funds.current = this.currentPositions.length * 1000;

      // approved fund is the sum of all planned & approved positions
      const approvedPositions = this.planPositions.filter(pos => pos.approval === 1);
      this.funds.approved = calcCost(approvedPositions);
      this.funds.approvedSideCosts = calcSideCost(approvedPositions);

      // pending fund is the sum of all planned & pending/non-yet-approved positions
      const pendingPositions = this.planPositions.filter(pos => pos.approval === 0);
      this.funds.pending = calcCost(pendingPositions);
      this.funds.pendingSideCosts = calcSideCost(pendingPositions);

      this.filteredPlanPositions = this.applyFilter(this.planPositions);
    },

    approvalToWord(approvalStatus) {
      if (approvalStatus === 11) return "2025 incremental";
      if (approvalStatus === 10) return "Not requested";
      if (approvalStatus === 1) return "Approved";
      if (approvalStatus === 0) return "Pending";
      if (approvalStatus === -1) return "Rejected";
      return "Unknown";
    },

    // Smart recruiter status
    statusToWord(status) {
      const MAP = ["NOT STARTED", "CREATED", "SOURCING", "INTERVIEW", "OFFER", "FILLED", "ON_HOLD", "CANCELLED"]
      return MAP[status] || "NOT STARTED";
    },

    // statusToWord(status) {
    //   if (status === 2) return "Hiring completed";
    //   if (status === 1) return "Pending approval";
    //   if (status === 0) return "Hiring started";
    //   if (status === -1) return "Cancelled"
    //   return "Unknown";
    // },

    toggleMarkForDeletion(pos, event) {
      if (this.editToDelete.includes(pos)) {
        this.editToDelete.splice(this.editToDelete.indexOf(pos), 1);
        this.$refs.positionsDataTable.querySelector(`[data-id='${pos.id}']`).classList.remove('delete');
        return;
      }

      this.editToDelete.push(pos);
      this.$refs.positionsDataTable.querySelector(`[data-id='${pos.id}']`).classList.add('delete');
    },

    addAnEmptyPosition() {
      let hiring_manager = "";
      let hiring_manager_name = "";
      if(!this.planData.current_user.can_edit_all_positions && this.planData.current_user.is_hiring_manager) {
        hiring_manager = this.planData.current_user.id;
        hiring_manager_name = this.planData.current_user.name;
      }

      const newPos = {
        position_id: "",
        approval: 10, // not requested
        status: 0,
        planned_quarter: "",
        job_family: 0,
        job_level: 0,
        hiring_manager: hiring_manager,
        hiring_manager_name: hiring_manager_name,
        priority: "",
        job_location: "",
        extra_data: {
          job_url: "",
          team_name: "",
          hired: false,
          position_open_date: "",
          target_start: "",
          hire_name: "",
          skill: "",
          backfill: "",
          fte: 100,
        },
        sr_data: {},
        recruiter: 0,
        last_sync: "",
        subplan: "",
        sr_status: 0,
        _class: "unsaved",
        pos_acs: {can_edit: true},
      }
      this.planPositions.push(newPos);
      this.filteredPlanPositions.push(newPos);

      // wait until the new element is rendered an then scoll to it
      this.$nextTick(function () {
        window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
      });
    },

    toggleEdit(cancel = true) {
      if (!this.editMode) {
        this.editMode = true;
      } else {
        this.editMode = false;
        if (cancel) {
          this.planPositions = null;
          this.fetchPlan();
        }
      }
    },

    async applyEdit() {
      let planID = this.$route.params.id;

      // JSON stringify extra_data for the API
      let positions = this.planPositions;
      positions.forEach(pos => {
        pos.extra_data = JSON.stringify(pos.extra_data);
      });

      let changedPositions = positions.filter(pos => pos._class === "unsaved" || pos._dirty);

      await putToApi(this, `/api/hcp/plans/${planID}/positions`, changedPositions);

      if (this.editToDelete.length) {
        await deleteFromApi(`/api/hcp/plans/${planID}/positions`, this.editToDelete);
        this.editToDelete = [];
      }

      // remove unsaved change markings
      positions.forEach(pos => {
        pos._class = null;
      });

      this.toggleEdit();
    },

    async submitForApproval(pos) {
      // update hiring manager comment
      // await postToApi(this, `/api/hcp/plans/${pos.plan}/positions/${pos.id}`, pos);
      let positions = [{ ...pos }]; // close position so we can safely stringify the extradata
      positions.forEach(pos => {
        pos.extra_data = JSON.stringify(pos.extra_data);
      });

      await putToApi(this, `/api/hcp/plans/${pos.plan}/positions`, positions);


      let url = `/api/hcp/plans/${this.planId}/positions/${pos.id}/approval`;
      let result = await postToApi(this, url, null, {approval_status: 0});
      pos.approval = result.approval_status;
      this.submitForApprovalDialog = null;
    },

    async sendCSVExportRequest(format) {
      let planID = this.$route.params.id;
      let url = `/api/hcp/plans/${planID}/csvexport`;
      let responseBlob = await downloadFileFromApi(this, url, {"format": format});

      let filename = `plan-${planID}-export-${formatDate(Date())}.csv`;
      let blob = new Blob([responseBlob], { type: 'text/csv' });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    },

    translateDateToQuarter(date) {
      let month = (new Date(date)).getMonth();
      if (month < 3) return "Q1";
      if (month < 6) return "Q2";
      if (month < 9) return "Q3";
      if (month < 12) return "Q4";
    },

    translateQuarterToDate(quarter) {
      let year = (new Date()).getFullYear();
      if (quarter === "Q1") return new Date(year, 0, 1);
      if (quarter === "Q2") return new Date(year, 3, 1);
      if (quarter === "Q3") return new Date(year, 6, 1);
      if (quarter === "Q4") return new Date(year, 9, 1);
    },

    mapPriority(value) {
      switch(value) {
        case 1:
          return "1 - Critical";
        case 2:
          return "2 - High";
        case 3:
          return "3 - Medium";
        case 4:
          return "4 - Low";
      }
    },

    duplicate(position, count) {
      if(count < 1) count = 1;
      if(count > 100) count = 100;

      let hiring_manager = null;
      let hiring_manager_name = null;
      if(!this.planData.current_user.can_edit_all_positions && this.planData.current_user.is_hiring_manager) {
        hiring_manager = this.planData.current_user.id;
        hiring_manager_name = this.planData.current_user.name;
      }

      for(let i = 0; i < count; i++) {
        let dup = {
          approval: 10, // pending
          sr_status: 0, // none
          status: position.status,
          planned_quarter: position.planned_quarter,
          job_profile: position.job_profile,
          job_profile_name: position.job_profile_name,
          job_location: position.job_location,
          job_location_name: position.job_location_name,
          priority: position.priority,
          hiring_manager: hiring_manager ? hiring_manager : position.hiring_manager, // hiring managers an only create positions for themself
          hiring_manager_name: hiring_manager? hiring_manager_name : position.hiring_manager_name,  // hiring managers an only create positions for themself
          extra_data: JSON.parse(JSON.stringify(position.extra_data)), // deep copy
          sr_data: {},
          last_sync: null,
          subplan: position.subplan,
          // sr_status: position.sr_status, NO duplicate
          _class: "unsaved",
          pos_acs: {can_edit: true},
        };

        dup.sr_data.job_url = null;
        dup.sr_data.hired = null;
        dup.sr_data.hire_name = null;
        dup.sr_data.position_open_date = null;

        this.planPositions.push(dup);
        this.filteredPlanPositions.push(dup);
      }

        this.duplicateDialog = null;

      // wait until the new elements are rendered an then scoll to it
      this.$nextTick(function () {
        window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
      });
    },

    showComment($event, pos, editMode, datafield, commentValue) {
      if (editMode || ($event.target.scrollWidth > $event.target.clientWidth && commentValue)) {
        const b= $event.target.getBoundingClientRect(); 
        this.showCommentPopup = {
          datafield: datafield,
          comment: commentValue,
          y:b.bottom, 
          x:(b.left + (b.right - b.left) / 2),
          edit: editMode,
          pos: pos
        }
        console.log(datafield)
      }
    },
    hideComment($event, pos) {
      this.showCommentPopup = null
    }
  },

  async beforeMount() {
    this.$root.appClass["page-helsinki"] = true;
    await this.fetchPlan();
    
    let planId = this.$route.params.id;
    if (this.planData.current_user.can_view_edit_mode) {
      this.editValues = await fetchFromApi("GET", `/api/hcp/plans/${planId}/editvalues`);
    }
  },

  async beforeUnmount() {
    this.$root.appClass["page-helsinki"] = false;
  }
}
</script>

<style lang="scss" scoped>

  .numinput:invalid {
    border-color: red;
    border-radius: 4px;
  }

  .current-positions-table {
    margin-top: 100px;
  }



  .data-table.edit td {
    padding: 2px 2px;
  }

  .data-table {
    margin-top: 0;
  }

  .data-table th {
    position: sticky;
    top: calc(var(--header-height) + 114px);
    background: white;
    min-width: 100px;
    line-height: 1.1em;
    padding-top: 1.5em;
    height: 2.2em;
  }

  .data-table td {
    background: none;
    min-width: 100px;
    height: 53px;
  }
  
  .data-table td.required {
    background-color: rgb(255, 0, 0, 0.05);
  }

  .data-table.edit td.required {
    border: 1px solid rgb(255, 0, 0, 0.4);
  }

  .data-table td {
    box-sizing: border-box;
    padding-left: 4px;
    padding-right: 4px;
  }

  .positions-edit-controls {
    display: flex;
    justify-content: flex-end;
    margin-top: 1em;

    button {
      margin-left: 1em;
    }
  }

  td button:not(:last-of-type) {
    margin-right: 5px;
  }

  tr.delete {
    background: #ff19002c;
  }

  tr.unsaved {
    background: #d8f3dc;
  }

  .comments {
    max-width: 200px;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    overflow: hidden;
    white-space: nowrap;
    width: 200px;
  }

  .job-location, .hiring-manager, .job-title {
    min-width: 200px!important;
  }

  .job-profile {
    text-wrap: nowrap;
  }

  // top filter bar overrides

  .actions-th, .actions-td {
    position: sticky!important;
    right: 0;
    // background-color: #f8f8f8!important;
    // background-color: rgba(255, 255, 255, 0.8)!important;
    text-align: center;
    text-wrap: nowrap;
    border-top: 1px solid #eee;
    border-bottom: 0;
    margin-top: -1px;

    // .btn-new.subtle-icon {
    //   // background-color: #f8f8f8!important;
    // }
  }
  .actions-th {
    min-width: 3em;
    background: white!important;
  }
  .actions-td {
    padding: 0;
    display: flex;
    justify-content: right;
    align-items: center;
  }

  .data-table thead th {
    padding-left: 4px;
  }

  .pos-table-data {
    input[type=date], input[type=text], input[type=number], .checkbox-edit {
      height: 3em;
      border: 0;
      background: #eee;
      border-radius: 2px;
      padding: 4px;
      width: 100%;
      box-sizing: border-box;
    }
    select {
      height: 2.5em;
      border: 0;
      background: #eee;
      border-radius: 2px;
      padding: 4px;
      font-size: 15px;
      width: 100%;
      height: 3em;
      box-sizing: border-box;
    }

    label.checkbox-edit {
      display: flex;
    }
  }

  table.current-positions {
    th:nth-child(1), th:nth-child(2), th:nth-child(3), th:nth-child(4) {
      width: 150px;
    }
  }

  .sortable {
    user-select: none;
    word-break: keep-all;
    cursor: pointer;
    &::after {
      content: ' ↓↑';
      visibility: hidden;
    }
  }
  .sortable:hover::after {
    content: ' ↓↑';
    visibility: visible;
  }
  .sortable.asc::after {
    content: ' ↓';
    visibility: visible;
  }
  .sortable.desc::after {
    content: ' ↑';
    visibility: visible;
  }

  // on hover row
  .data-table tr .onhover, .data-table tr:hover .not-on-hover {
    display: none;
  }

  .not-on-hover {
    background: white;
    width: 3em;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
    border-radius: 50px 0 0 50px;
  }


  .data-table tr:hover .onhover {
    display: initial;
    display: flex;
    height: 100%;
    align-items: center;
    background: white;
    overflow-x: hidden;
    padding: 0 5px;
    // translate: 0 -22px;
    padding: 0;
    height: 35px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }

  th.sr-synced {
    text-shadow: 0px 0px 2px rgba(0,255,0,0.5);
  }

  tr.dirty {
    background-color: #009de01f;
  }
  .comments-edit-td {
    background-color: #eee!important;
    border-radius: 2px;
    padding: 4px!important;
    cursor: pointer;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 200px;
  }

  .comments-edit {
    font-family: 'Roboto',sans-serif;
    min-width: 220px;
    min-height: 100px;
  }

  .hcp-plan-quickfilter {
    font-weight: 400; 
    font-size: 14px;    
    display: inline-flex;
    align-items: center;
    gap: 1ex;
    // margin-right: 2em;
    select  {
      border-color: #ddd;
      border-radius: 4px;
      height: 2em;
      background-color: #fbfbfb;
    }

    // border: 1px dotted #ddd;
    border-radius: 4px;
    padding-right: 1ex;
    padding-left: 1em;

    i {
      font-size: 17px;
      color: #333;
      border-right: 1px dotted #ddd;
      padding-right: 1ex;
    }

    button.active {
      background: #44444425;
    }
  }

  a.bubble {
    padding: .7ex;
    text-decoration: none;
    color: black;
    font-weight: 400;
    text-transform: uppercase;
    opacity: .5;
  
    &:hover {
      background-color: #f8f8f8;
      filter: brightness(0.95);
      border-radius: 4px;
      opacity: .75;
      
      color: inherit!important;
    }
  }

  span.badge {
    background: #ddd;
    border-radius: 50%;
    display: inline-block;
    width: 1.6em;
    font-size: smaller;
    margin-left: 1ex;
    color: #333;
    font-weight: 400;
  }
  .role-list {
    width: 350px;
    display: flex;
    flex-wrap: wrap;
    span {
      font-family: monospace;
      font-size: smaller;
      border: 1px solid rgba(29, 28, 29, 0.13);
      border-radius: 3px;
      padding: 1px .5ex;
      margin: 2px;
      color: rgb(224, 30, 90);
      background-color: rgba(29, 28, 29, 0.04);
    }
    span.permission {
      color: #666;
    }
  }
  .export-menu {
    position: fixed;
    border: #ddd 1px solid;
    border-radius: 3px;
    padding: 2px;
    translate: -62px;
    background-color: white;
    display: flex;
    flex-direction: column;
  }
</style>
<style lang="scss">
  @import "~@/styles/hcp_shared.scss";

  .hcp-history-modal {
    width: 80%!important;
    min-width: 700px!important;
    max-width: 1500px!important;
    max-height: 90%; // using 90vh makes dialog rendering blurry

    .details-modal-content {
      min-height: 200px;
    }
  }

  .no-subplans-notice {
    margin-top: 1em;
    font-size: .8rem;
    color: #333;
  }

  .tooltip, .tooltip-above {
    position:relative; /* making the .tooltip span a container for the tooltip text */
    // border-bottom:1px dashed #000; /* little indicater to indicate it's hoverable */
  
    &:before {
      content: attr(data-text); /* here's the magic */
      position:absolute;

      /* vertically center */
      top:50%;
      transform:translateY(-50%);
      
      /* move to right */
      left:100%;
      margin-left:10px; /* and add a small left margin */
      
      /* basic styles */
      width:200px;
      padding:10px;
      border-radius:8px;
      background:#000;
      color: #fff;
      text-align:center;

      display:none; /* hide by default */
    }
    
    &:after {
      content: "";
      position:absolute;
      
      /* position tooltip correctly */
      left:100%;
      margin-left:-6px;
    
      /* vertically center */
      top:50%;
      transform:translateY(-50%);
    
      /* the arrow */
      border:10px solid #000;
      border-color: transparent black transparent transparent;
      
      display:none;
    }

    &:hover:before, &:hover:after {
      display:block;
    }
  }

  .tooltip-above {
    &:before {
      transform: translate(-50%, -125%);
      padding: 10px 2em;
      width: auto;
      margin-left: 0;
      left: 50%;
      top: 0;
    }
    &:after {
      transform: translate(-50%, -60%);
      border-color: black transparent transparent transparent;
      margin-left: 0;
      left: 50%;
      top: 0;
    }
  }
  .tt-small {
    &:before {
      font-size: smaller;
    }
  }

</style>